@tailwind base;
@tailwind components;
@tailwind utilities;


.fontStevie{
  /* font-family: 'Playfair Display', serif; */
}

.btnStevie {
  background: linear-gradient(to bottom, #bd9602 0%, #d4aa00 100%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.btnStevie2 {
  background: #141414;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.btnStevie3 {
  /* position: absolute; */
  /* bottom: -20px; */
  /* right: -10px; */
  background: #191919;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.backStevie {
  /* position: absolute; */
  /* bottom: -20px; */
  left: -10px;
  background: #4d1a1a;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}
h2 {
    font-weight: 700;
    font-size: 18px;
}

/* Add these styles to your CSS */
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 8px; /* Adjust the padding as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide content that overflows */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
 
    transform: translate(-50%, -50% );
    /* background-color: white; */
    padding: 20px;
    /* border: 4px solid #040404; */
    /* border-radius: 10px; */
    outline: none;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */
    /* max-width: 80%; */
    width: 60vw;
    height: 80vh;
    margin: auto;
    text-align: center;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 20px;
    color: rgb(10, 10, 10);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-header button {
    background-color: black;
  }

  .tableContainer {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
  }
